const prod = {
    url: {
        API_URL_BN: 'https://bnapi.bdcrictime.com/',
        API_URL: 'https://api.bdcrictime.com/',
        AUTH_API_URL: 'https://accounts.bdcrictime.com/',
        APP_URL: 'https://bdcrictime.com/',
    }
};
const dev = {
    url: {
        API_URL_BN: 'http://localhost:5000/',
        API_URL: 'http://localhost:5000/',
        AUTH_API_URL: 'http://localhost:8000/',
        APP_URL: 'http://localhost:4000/',
    }
};
// export const config = process.env.NODE_ENV === 'development' ? dev : prod;
export const config = prod;